import React, { useCallback, useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { usePrevious, useRenderCount, SearchFieldEnum, useGlobalContext, } from '@honeycomb/data';
import { Autocompleter, AutocompleterCategory, AutocompleterField, AutocompleterList, AutocompleterOption, AutocompleterResults, LocationIcon, HotelIcon, ButtonField, SearchIcon, PopoverAnchor, } from '@honeycomb/ui-core';
import { SearchDisplayType, useSearchContext } from '../SearchContext';
import { SearchDispatchActionType } from '../SearchReducer';
import { AutocompleterSkeleton } from '../components/AutocompleterSkeleton';
import { AutocompleterNoMatches } from '../components/AutocompleterNoMatches';
import { FieldDialog } from '../components/FieldDialog';
import { FieldErrorMessage } from '../components/FieldErrorMessage';
import { pluraliseCount } from '../../../utils/pluraliseCount';
import { SearchFieldPopover } from '../components/SearchFieldPopover/SearchFieldPopover';
import { SearchFieldPopoverPanel } from '../components/SearchFieldPopover/SearchFieldPopoverPanel';
const parseAutocompleterValue = (value) => {
    if (value[0] === 'H') {
        return {
            hotelId: Number(value.substring(2)),
        };
    }
    if (value[0] === 'D') {
        return {
            destinationId: Number(value.substring(2)),
        };
    }
    return {};
};
const toAutocompleterValue = (searchState) => {
    if (searchState.destinationIds) {
        return `D-${searchState.destinationIds}`;
    }
    if (searchState.hotelIds) {
        return `H-${searchState.hotelIds}`;
    }
    return undefined;
};
// Define the translation functions between schema and autocompleter option
const mapDestinationToOption = (result) => {
    const { destinationId, title, subTitle, hotelCount } = result;
    return {
        value: `D-${destinationId}`,
        id: `option-${destinationId}`,
        title,
        subTitle: subTitle !== null && subTitle !== void 0 ? subTitle : undefined,
        appendix: hotelCount ? `${hotelCount} hotels` : undefined,
        type: 'destination',
    };
};
const mapHotelToOption = (result) => {
    const { hotelId, title, subTitle } = result;
    return {
        value: `H-${hotelId}`,
        id: `option-${hotelId}`,
        title,
        subTitle: subTitle !== null && subTitle !== void 0 ? subTitle : undefined,
        type: 'hotel',
    };
};
// Define the function to build the list of options for a given set of destinations/hotels
const buildOptionsList = (options, labelledById) => {
    return (React.createElement(AutocompleterList, { labelledById: labelledById }, options.map((option) => {
        const { id, value, title, subTitle: subtitle } = option;
        return (React.createElement(AutocompleterOption, { key: `option-${id}`, id: id, value: value, title: title, subtitle: subtitle, icon: option.type === 'hotel' ? React.createElement(HotelIcon, null) : React.createElement(LocationIcon, null), searchTermMatchedTo: ['title', 'subtitle'] }));
    })));
};
const autocompleterFieldId = 'destination-autocompleter-field';
const autocompleterListId = 'destination-autocompleter-list';
const dialogAutocompleterFieldId = `${autocompleterFieldId}-dialog`;
export function DestinationAutocompleter({ state, actions }) {
    var _a;
    const { data, previousData, loading, searchClicked, error: errorProp, popupPlacement = 'bottom-start', called, } = state;
    const { fetchData, onDone, setSearchClicked } = actions;
    const { openField, onOpen, onClose, displayType, searchDispatch, searchState, fieldValidity, searchError } = useSearchContext();
    const { resourceStrings: { AUTOCOMPLETER_RESULTS_POPULAR, HC_DESTINATIONS, SEARCH_DESTINATION_ERROR_EMPTY, SEARCH_DESTINATION_FIELD_LABEL, SEARCH_DESTINATION_FIELD_PLACEHOLDER, HC_HOTELS, RESULT_COUNT_MESSAGE, RESULTS_COUNT_MESSAGE, }, } = useGlobalContext();
    // DEV ONLY: Count the number of renders of the component
    useRenderCount('DestinationAutoCompleter');
    // Set up state management for various functions of the autocompleter
    const [destinationsTitle, setDestinationsTitle] = React.useState(AUTOCOMPLETER_RESULTS_POPULAR);
    const [updateId, setUpdateId] = React.useState('');
    // Is the search currently open on this field
    const open = openField === SearchFieldEnum.DESTINATION;
    const error = ((_a = fieldValidity[SearchFieldEnum.DESTINATION]) === null || _a === void 0 ? void 0 : _a.error) || false;
    const searchTitle = searchState.hotelTitle || searchState.destinationTitle || '';
    // Get the data and store the previous instance to smoothly switch when it changes
    const prevLoading = usePrevious(loading);
    const prevOpen = usePrevious(open);
    const prevUpdateId = usePrevious(updateId);
    const popup = displayType === SearchDisplayType.POPUP;
    const dialog = displayType === SearchDisplayType.DIALOG;
    const [scrollingPopoverElement, setScrollingPopoverElement] = React.useState(null);
    // Extract the destination and hotel data to be displayed
    const destinations = loading && previousData
        ? ((previousData === null || previousData === void 0 ? void 0 : previousData.destinationAutocompleterResults.destinations) || []).map(mapDestinationToOption)
        : ((data === null || data === void 0 ? void 0 : data.destinationAutocompleterResults.destinations) || []).map(mapDestinationToOption);
    const hotels = loading && previousData
        ? ((previousData === null || previousData === void 0 ? void 0 : previousData.destinationAutocompleterResults.hotels) || []).map(mapHotelToOption)
        : ((data === null || data === void 0 ? void 0 : data.destinationAutocompleterResults.hotels) || []).map(mapHotelToOption);
    const matchesFound = destinations.length > 0 || hotels.length > 0;
    // Determine if the default title needs to be shown based on the last results
    useEffect(() => {
        if (!loading && prevLoading) {
            setDestinationsTitle(searchTitle === '' ? AUTOCOMPLETER_RESULTS_POPULAR : HC_DESTINATIONS);
        }
    }, [loading, prevLoading, searchTitle, AUTOCOMPLETER_RESULTS_POPULAR, HC_DESTINATIONS]);
    useEffect(() => {
        if (prevOpen && !open) {
            if (!searchState.destinationIds && !searchState.hotelIds) {
                searchDispatch({ type: SearchDispatchActionType.SET_DESTINATION_TITLE });
                searchDispatch({ type: SearchDispatchActionType.SET_HOTEL_TITLE });
            }
        }
    }, [prevOpen, open, searchDispatch, searchState.destinationIds, searchState.hotelIds]);
    useEffect(() => {
        if (updateId && updateId !== prevUpdateId) {
            if (onDone) {
                onDone();
            }
            else {
                onClose();
            }
        }
    }, [onClose, onDone, prevUpdateId, updateId]);
    // Define the functions for the autocompleter to work
    const handleFieldChange = useCallback((event) => {
        const { value } = event.currentTarget;
        searchDispatch({ type: SearchDispatchActionType.SET_HOTEL_TITLE, destinationTitleValue: value });
        searchDispatch({ type: SearchDispatchActionType.SET_DESTINATION_TITLE, destinationTitleValue: value });
        searchDispatch({ type: SearchDispatchActionType.SET_DESTINATION_ID });
        searchDispatch({ type: SearchDispatchActionType.SET_HOTEL_ID });
        setSearchClicked(false);
    }, [searchDispatch, setSearchClicked]);
    const handleClearSearchTitle = () => {
        searchDispatch({ type: SearchDispatchActionType.SET_HOTEL_TITLE, destinationTitleValue: '' });
        searchDispatch({ type: SearchDispatchActionType.SET_DESTINATION_TITLE, destinationTitleValue: '' });
    };
    const handleAdd = useCallback((value, title) => {
        var _a, _b;
        searchDispatch({ type: SearchDispatchActionType.SET_DESTINATION_TITLE, destinationTitleValue: title });
        searchDispatch({ type: SearchDispatchActionType.SET_HOTEL_TITLE, destinationTitleValue: title });
        const ids = parseAutocompleterValue(value);
        // TODO: To be removed, work around for now
        if (((_a = searchState.destinationIds) === null || _a === void 0 ? void 0 : _a[0]) === ids.destinationId &&
            ((_b = searchState.hotelIds) === null || _b === void 0 ? void 0 : _b[0]) === ids.hotelId &&
            onDone) {
            onDone();
        }
        else {
            // TODO: To be removed, work around for now
            const destinationId = ids.destinationId ? [ids.destinationId] : [];
            const hotelId = ids.hotelId ? [ids.hotelId] : [];
            searchDispatch({
                type: SearchDispatchActionType.SET_DESTINATION_ID,
                destinationIdValue: destinationId,
            });
            searchDispatch({ type: SearchDispatchActionType.SET_HOTEL_ID, destinationIdValue: hotelId });
            // Trigger a render update to close/open next field with data which has just been set on next tick
            setUpdateId(uuid());
        }
    }, [onDone, searchDispatch, searchState.destinationIds, searchState.hotelIds]);
    const handleRemove = useCallback(() => {
        searchDispatch({ type: SearchDispatchActionType.SET_DESTINATION_TITLE });
        searchDispatch({ type: SearchDispatchActionType.SET_HOTEL_TITLE });
        searchDispatch({ type: SearchDispatchActionType.SET_DESTINATION_ID });
        searchDispatch({ type: SearchDispatchActionType.SET_HOTEL_ID });
    }, [searchDispatch]);
    // Build the three sections of the children results out of destinations, the divider and the hotels
    const resultsChildren = [];
    if (destinations.length > 0) {
        resultsChildren.push(React.createElement(AutocompleterCategory, { title: destinationsTitle, appendix: searchTitle !== ''
                ? pluraliseCount(destinations.length, {
                    singular: RESULT_COUNT_MESSAGE,
                    plural: RESULTS_COUNT_MESSAGE,
                })
                : undefined, icon: React.createElement(LocationIcon, null), id: "category-destinations", key: "destinations" }, buildOptionsList(destinations, 'category-destinations')));
    }
    if (hotels.length > 0) {
        resultsChildren.push(React.createElement(AutocompleterCategory, { title: HC_HOTELS, appendix: searchTitle !== ''
                ? pluraliseCount(hotels.length, {
                    singular: RESULT_COUNT_MESSAGE,
                    plural: RESULTS_COUNT_MESSAGE,
                })
                : undefined, icon: React.createElement(HotelIcon, null), id: "category-hotels", key: "hotels" }, buildOptionsList(hotels, 'category-hotels')));
    }
    // Build the final results element including "no matches" case for empty searches or apollo errors
    const autoCompleterResults = (children) => {
        if (!called || loading) {
            return React.createElement(AutocompleterSkeleton, null);
        }
        if (matchesFound) {
            return (React.createElement(AutocompleterResults, { id: autocompleterListId, label: "Suggest destinations" }, children));
        }
        return React.createElement(AutocompleterNoMatches, null);
    };
    const fieldError = (React.createElement(FieldErrorMessage, { error: error, message: SEARCH_DESTINATION_ERROR_EMPTY, "data-id": "destination-error-message" }));
    const commonFieldProps = useMemo(() => ({
        fullWidth: true,
        error,
        variant: 'alternative',
    }), [error]);
    const label = SEARCH_DESTINATION_FIELD_LABEL;
    const placeholder = SEARCH_DESTINATION_FIELD_PLACEHOLDER;
    const showClear = searchTitle.length > 0;
    const handleClickOrFocus = () => {
        if (!open) {
            onOpen(SearchFieldEnum.DESTINATION);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Autocompleter, { fetch: fetchData, onAdd: handleAdd, onRemove: handleRemove, selectedValue: toAutocompleterValue(searchState) },
            React.createElement(React.Fragment, null,
                popup && open && (React.createElement(SearchFieldPopover, { open: open, onClose: () => onClose(), placement: popupPlacement, error: searchError, dynamicHeight: true, scrollingElement: scrollingPopoverElement },
                    React.createElement(PopoverAnchor, null,
                        React.createElement(AutocompleterField, Object.assign({ id: autocompleterFieldId, listId: autocompleterListId, value: error && searchClicked ? '' : searchTitle, label: label, placeholder: placeholder, onChange: handleFieldChange, autoFocus: true, shrinkLabel: true, size: { xs: 'm', m: 'l' }, showClear: showClear, onButtonClick: handleClearSearchTitle }, commonFieldProps))),
                    React.createElement(SearchFieldPopoverPanel, { width: 375, scrollingElementRef: (ref) => setScrollingPopoverElement(ref) }, autoCompleterResults(resultsChildren)))),
                !open && (
                // A button acts as a trigger to open the popup or dialog.
                React.createElement(ButtonField, Object.assign({ value: searchTitle, label: label, onClick: handleClickOrFocus, onFocus: () => handleClickOrFocus(), size: { xs: 'm', m: 'l' } }, commonFieldProps))),
                fieldError),
            dialog && (React.createElement(FieldDialog, { open: open, onClose: onClose, title: label, headingProps: {
                    component: 'label',
                    htmlFor: dialogAutocompleterFieldId,
                }, headerContent: React.createElement(React.Fragment, null,
                    React.createElement(AutocompleterField, Object.assign({ id: dialogAutocompleterFieldId, listId: autocompleterListId, value: searchTitle, placeholder: placeholder, onChange: handleFieldChange, autoFocus: true, icon: React.createElement(SearchIcon, null), showClear: showClear, onButtonClick: handleClearSearchTitle }, commonFieldProps)),
                    fieldError) }, autoCompleterResults(resultsChildren)))),
        errorProp && React.createElement("div", null, errorProp.message)));
}
