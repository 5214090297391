import { SearchFieldEnum, useGlobalContext, usePrevious, useRenderCount, AnywhereSearchResultEnum, getRecentSearch, parseBoolean, } from '@honeycomb/data';
import { Autocompleter, AutocompleterCategory, AutocompleterField, AutocompleterList, AutocompleterOption, AutocompleterResults, HotelIcon, ButtonField, LocationIcon, Stacker, SearchIcon, Anchor, ScrollArea, Styler, useBreakpoints, Chip, FlexGrid, Button, ChevronDownIcon, ChevronUpIcon, Notification, InformationIcon, PopoverAnchor, } from '@honeycomb/ui-core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { SearchDisplayType, useSearchContext } from '../SearchContext';
import { SearchDispatchActionType } from '../SearchReducer';
import { AutocompleterSkeleton } from '../components/AutocompleterSkeleton';
import { FieldErrorMessage } from '../components/FieldErrorMessage';
import { FieldDialog } from '../components/FieldDialog';
import { AutocompleterNoMatches } from '../components/AutocompleterNoMatches';
import { pluraliseCount } from '../../../utils/pluraliseCount';
import { SearchFieldPopover } from '../components/SearchFieldPopover/SearchFieldPopover';
import { SearchFieldPopoverPanel } from '../components/SearchFieldPopover/SearchFieldPopoverPanel';
import { SEARCH_FIELD_POPOVER_INNER_PADDING } from '../../../utils/style-constants';
const anywhereOptions = [
    {
        id: Number(AnywhereSearchResultEnum.ANYWHERE_ID),
        title: `${AnywhereSearchResultEnum.TITLE}`,
        type: Number(AnywhereSearchResultEnum.TYPE),
        hotelCount: 0,
        isCached: true,
    },
];
// Define the function to build the list of options for a given set of destinations/hotels
const buildOptionsList = (options, labelledById, showSelection = true) => {
    return (React.createElement(AutocompleterList, { labelledById: labelledById }, options.map((option) => {
        const { id, type, title, subTitle: subtitle, isCached } = option;
        return (React.createElement(AutocompleterOption, { key: `option-${id}-${type}`, id: `${id}-${type}`, value: `${id}-${type}`, title: title, subtitle: subtitle !== null && subtitle !== void 0 ? subtitle : '', icon: option.type === 1 ? React.createElement(HotelIcon, null) : React.createElement(LocationIcon, null), selectable: showSelection ? Boolean(isCached) : false }));
    })));
};
const autocompleterFieldId = 'destination-autocompleter-field';
const autocompleterListId = 'destination-autocompleter-list';
const dialogAutocompleterFieldId = `${autocompleterFieldId}-dialog`;
export function getDestinationSummary(searchState) {
    var _a, _b;
    if ((_a = searchState.destinations) === null || _a === void 0 ? void 0 : _a.length) {
        const title = (_b = searchState.destinations[0]) === null || _b === void 0 ? void 0 : _b.title;
        const count = searchState.destinations.length;
        const checkCount = count > 2 ? 's' : '';
        const setTitle = `${title} (+ ${count - 1} other${checkCount})`;
        return searchState.hotelTitle || (count > 1 ? setTitle : title) || '';
    }
    return '';
}
export function MultiSearchDestinationAutocompleter({ state, actions, }) {
    var _a, _b, _c, _d;
    const [showAllRecentOptions, setShowAllRecentOptions] = useState(false);
    const { largeUp } = useBreakpoints();
    const recentSearches = getRecentSearch();
    const recentOptions = recentSearches && recentSearches.length > 0 ? JSON.parse(recentSearches) : [];
    const { data, previousData, loading, searchClicked, error: errorProp, popupPlacement = 'bottom-start', called, } = state;
    const { fetchData, onDone, setSearchClicked } = actions;
    const { openField, onOpen, onClose, displayType, searchDispatch, searchState, fieldValidity, searchError } = useSearchContext();
    const { resourceStrings: { HC_TOP_SELLERS, HC_RECENT_SEARCHES, HC_DESTINATIONS, SEARCH_DESTINATION_ERROR_EMPTY, HC_HOTELS, CLEARRECENTHOTELS, SEARCH_DESTINATION_FIELD_LABEL, SEARCH_DESTINATION_FIELD_PLACEHOLDER, RESULT_COUNT_MESSAGE, RESULTS_COUNT_MESSAGE, NONCACHED_FIELD_ERROR_MESSAGE, }, configSettings: { HCEnableDestinationGroups }, } = useGlobalContext();
    const destinationGroups = (_b = (_a = data === null || data === void 0 ? void 0 : data.destinationAutocompleterResults) === null || _a === void 0 ? void 0 : _a.destinationGroups) !== null && _b !== void 0 ? _b : [];
    // DEV ONLY: Count the number of renders of the component
    useRenderCount('DestinationAutoCompleter');
    // // Set up state management for various functions of the autocompleter
    const selectedValues = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = searchState.destinations) === null || _a === void 0 ? void 0 : _a.map((d) => `${d === null || d === void 0 ? void 0 : d.id}-${d === null || d === void 0 ? void 0 : d.type}`)) !== null && _b !== void 0 ? _b : [];
    }, [searchState.destinations]);
    const [selectionError, setSelectionError] = React.useState(false);
    const [updateId, setUpdateId] = React.useState('');
    const [searchTerm, setSearchTerm] = React.useState('');
    // Is the search currently open on this field
    const open = openField === SearchFieldEnum.DESTINATION;
    const error = (_d = (_c = fieldValidity[SearchFieldEnum.DESTINATION]) === null || _c === void 0 ? void 0 : _c.error) !== null && _d !== void 0 ? _d : false;
    const withSearchTerm = searchTerm !== '';
    const withSelections = selectedValues.length > 0;
    // Get the data and store the previous instance to smoothly switch when it changes
    const prevOpen = usePrevious(open);
    const prevUpdateId = usePrevious(updateId);
    const popup = displayType === SearchDisplayType.POPUP;
    const dialog = displayType === SearchDisplayType.DIALOG;
    const [scrollingPopoverElement, setScrollingPopoverElement] = React.useState(null);
    // Extract the destination and hotel data to be displayed
    const destinationOptions = useMemo(() => {
        var _a, _b;
        return loading && previousData
            ? (_a = previousData === null || previousData === void 0 ? void 0 : previousData.destinationAutocompleterResults.destinations) !== null && _a !== void 0 ? _a : []
            : (_b = data === null || data === void 0 ? void 0 : data.destinationAutocompleterResults.destinations) !== null && _b !== void 0 ? _b : [];
    }, [loading, previousData, data]);
    const destinationGroupOptions = useMemo(() => {
        var _a, _b;
        return loading && previousData
            ? (_a = previousData === null || previousData === void 0 ? void 0 : previousData.destinationAutocompleterResults.destinationGroups) !== null && _a !== void 0 ? _a : []
            : (_b = data === null || data === void 0 ? void 0 : data.destinationAutocompleterResults.destinationGroups) !== null && _b !== void 0 ? _b : [];
    }, [loading, previousData, data]);
    const hotelOptions = useMemo(() => {
        var _a, _b;
        return loading && previousData
            ? (_a = previousData === null || previousData === void 0 ? void 0 : previousData.destinationAutocompleterResults.hotels) !== null && _a !== void 0 ? _a : []
            : (_b = data === null || data === void 0 ? void 0 : data.destinationAutocompleterResults.hotels) !== null && _b !== void 0 ? _b : [];
    }, [loading, previousData, data]);
    const matchesFound = destinationOptions.length > 0 || hotelOptions.length > 0;
    const summary = useMemo(() => {
        return getDestinationSummary(searchState);
    }, [searchState]);
    useEffect(() => {
        if (prevOpen && !open) {
            if (!searchState.destinationId && !searchState.hotelId) {
                searchDispatch({ type: SearchDispatchActionType.SET_DESTINATION_TITLE });
                searchDispatch({ type: SearchDispatchActionType.SET_HOTEL_TITLE });
            }
        }
    }, [prevOpen, open, searchDispatch, searchState.destinationId, searchState.hotelId]);
    useEffect(() => {
        if (updateId && updateId !== prevUpdateId) {
            if (onDone) {
                onDone();
            }
            else {
                onClose();
            }
            onClose();
        }
    }, [onClose, onDone, prevUpdateId, updateId]);
    // Define the functions for the autocompleter to work
    const handleFieldChange = useCallback((event) => {
        const { value } = event.currentTarget;
        setSearchTerm(value);
        setSearchClicked(false);
        setSelectionError(false);
    }, [setSearchClicked]);
    const handleRemove = useCallback((value) => {
        searchDispatch({
            type: SearchDispatchActionType.REMOVE_FLEXIBLE_DESTINATION,
            idAndType: value,
        });
        setSelectionError(false);
    }, [searchDispatch]);
    const handleClearAll = () => {
        setSelectionError(false);
        searchDispatch({
            type: SearchDispatchActionType.CLEAR_FLEXIBLE_DESTINATION,
        });
    };
    const handleAdd = (value) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        const [id, type] = value.split('-');
        if (id && type) {
            const destinationObject = (_d = (_c = (_b = (_a = destinationOptions.find((option) => String(option.id) === id && String(option.type) === type)) !== null && _a !== void 0 ? _a : hotelOptions.find((option) => String(option.id) === id && String(option.type) === type)) !== null && _b !== void 0 ? _b : destinationGroupOptions.find((option) => String(option.id) === id && String(option.type) === type)) !== null && _c !== void 0 ? _c : anywhereOptions.find((option) => String(option.id) === id && String(option.type) === type)) !== null && _d !== void 0 ? _d : recentOptions.find((option) => String(option.id) === id && String(option.type) === type);
            if ((destinationObject === null || destinationObject === void 0 ? void 0 : destinationObject.id) === AnywhereSearchResultEnum.ANYWHERE_ID ||
                (!((_e = searchState.destinations) === null || _e === void 0 ? void 0 : _e.length) && !(destinationObject === null || destinationObject === void 0 ? void 0 : destinationObject.isCached))) {
                onClose();
                setUpdateId(uuid());
            }
            if (((_f = searchState.destinations) === null || _f === void 0 ? void 0 : _f.some((d) => d === null || d === void 0 ? void 0 : d.isCached)) && !(destinationObject === null || destinationObject === void 0 ? void 0 : destinationObject.isCached)) {
                setSelectionError(true);
                return;
            }
            if (destinationObject === null || destinationObject === void 0 ? void 0 : destinationObject.isCached) {
                setSelectionError(false);
            }
            searchDispatch({
                type: SearchDispatchActionType.ADD_FLEXIBLE_DESTINATION,
                destination: {
                    id: (_g = destinationObject === null || destinationObject === void 0 ? void 0 : destinationObject.id) !== null && _g !== void 0 ? _g : 0,
                    type: (_h = destinationObject === null || destinationObject === void 0 ? void 0 : destinationObject.type) !== null && _h !== void 0 ? _h : 0,
                    title: (_j = destinationObject === null || destinationObject === void 0 ? void 0 : destinationObject.title) !== null && _j !== void 0 ? _j : '',
                    isCached: (_k = destinationObject === null || destinationObject === void 0 ? void 0 : destinationObject.isCached) !== null && _k !== void 0 ? _k : false,
                    subTitle: (_l = destinationObject === null || destinationObject === void 0 ? void 0 : destinationObject.subTitle) !== null && _l !== void 0 ? _l : '',
                },
            });
        }
    };
    const onPopupClose = () => {
        if (selectedValues.length) {
            setUpdateId(uuid());
        }
        onClose();
        setSelectionError(false);
    };
    const selectedOptionPills = (withClear, inHeader) => {
        var _a, _b, _c, _d;
        if ((_a = searchState.destinations) === null || _a === void 0 ? void 0 : _a.some((x) => `${x === null || x === void 0 ? void 0 : x.id}-${x === null || x === void 0 ? void 0 : x.type}` === `${AnywhereSearchResultEnum.ANYWHERE_ID}-${AnywhereSearchResultEnum.TYPE}`)) {
            return null;
        }
        return (React.createElement(Styler, { pos: "relative", display: "flex" },
            React.createElement(ScrollArea, { flex: "1 1 auto" },
                React.createElement(Stacker, { spacing: 2, direction: "row", alignItems: "center" }, (_b = searchState.destinations) === null || _b === void 0 ? void 0 : _b.map((item) => (React.createElement(Chip, { size: "xs", color: inHeader ? 'neutral' : 'white', onDismiss: () => handleRemove(`${item === null || item === void 0 ? void 0 : item.id}-${item === null || item === void 0 ? void 0 : item.type}`), key: item === null || item === void 0 ? void 0 : item.id, "data-id": "multi-autocomp-pill" }, item === null || item === void 0 ? void 0 : item.title))))),
            ((_d = (_c = searchState.destinations) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0) > 0 && withClear && (React.createElement(Styler, { display: "flex", alignItems: "center", flex: "0 1 auto", bg: inHeader ? undefined : 'background.offset', pl: 3, zIndex: 1 },
                React.createElement(Anchor, { button: true, onClick: handleClearAll, size: "xs", weight: "semiBold", truncate: true, "data-id": "multi-autocomp-clear" }, CLEARRECENTHOTELS)))));
    };
    const destinationCategoryTitle = withSearchTerm ? HC_DESTINATIONS : HC_TOP_SELLERS;
    const getRecentCategory = () => {
        if (recentOptions.length === 0) {
            return null;
        }
        const maxItems = !largeUp ? 3 : null;
        const visibleItems = maxItems && !showAllRecentOptions ? recentOptions.slice(0, maxItems) : recentOptions;
        const hiddenCount = showAllRecentOptions ? 0 : recentOptions.length - (maxItems || 0);
        const button = maxItems && recentOptions.length > maxItems ? (React.createElement(Styler, { display: "flex", justifyContent: "center" },
            React.createElement(Button, { color: "neutral", size: "xs", icon: !showAllRecentOptions ? React.createElement(ChevronDownIcon, { size: "xs" }) : React.createElement(ChevronUpIcon, { size: "xs" }), onClick: () => setShowAllRecentOptions((show) => !show), iconPosition: "end" }, !showAllRecentOptions ? `Show ${hiddenCount} more` : 'Show less'))) : undefined;
        return (React.createElement(AutocompleterCategory, { title: HC_RECENT_SEARCHES, appendix: pluraliseCount(recentOptions.length, {
                singular: RESULT_COUNT_MESSAGE,
                plural: RESULTS_COUNT_MESSAGE,
            }), id: "category-recent", footerContent: button }, buildOptionsList(visibleItems, 'category-recent')));
    };
    const destinationsCategory = React.useMemo(() => {
        return destinationOptions.length || hotelOptions.length ? (React.createElement(AutocompleterCategory, { title: destinationCategoryTitle, appendix: pluraliseCount(destinationOptions.length, {
                singular: RESULT_COUNT_MESSAGE,
                plural: RESULTS_COUNT_MESSAGE,
            }), id: "category-destinations" }, destinationOptions.length ? buildOptionsList(destinationOptions, 'category-destinations') : undefined)) : null;
    }, [
        RESULTS_COUNT_MESSAGE,
        RESULT_COUNT_MESSAGE,
        destinationCategoryTitle,
        destinationOptions,
        hotelOptions.length,
    ]);
    const destinationsCombinedCategory = React.useMemo(() => {
        const destinationGroupOptionsComb = destinationGroupOptions;
        const destinationOptionsComb = destinationOptions;
        return destinationOptions.length || hotelOptions.length ? (React.createElement(AutocompleterCategory, { title: destinationCategoryTitle, appendix: pluraliseCount(destinationOptions.length, {
                singular: RESULT_COUNT_MESSAGE,
                plural: RESULTS_COUNT_MESSAGE,
            }), id: "category-destinations" }, destinationOptions.length
            ? buildOptionsList(destinationGroupOptionsComb.concat(destinationOptionsComb), 'category-destinations')
            : undefined)) : null;
    }, [
        RESULTS_COUNT_MESSAGE,
        RESULT_COUNT_MESSAGE,
        destinationGroupOptions,
        destinationCategoryTitle,
        destinationOptions,
        hotelOptions.length,
    ]);
    const hotelsCategory = React.useMemo(() => {
        return destinationOptions.length || hotelOptions.length ? (React.createElement(AutocompleterCategory, { title: HC_HOTELS, appendix: pluraliseCount(hotelOptions.length, {
                singular: RESULT_COUNT_MESSAGE,
                plural: RESULTS_COUNT_MESSAGE,
            }), id: "category-hotels" }, hotelOptions.length ? buildOptionsList(hotelOptions, 'category-hotels') : undefined)) : null;
    }, [HC_HOTELS, RESULTS_COUNT_MESSAGE, RESULT_COUNT_MESSAGE, hotelOptions, destinationOptions.length]);
    const getFirstColumnCategories = () => {
        const result = [];
        // If we have a search term then we want to display matching destinations.
        // If there are no matching destinations then display hotels instead.
        if (withSearchTerm) {
            if (destinationsCombinedCategory) {
                result.push(destinationsCombinedCategory);
            }
            else if (hotelsCategory) {
                result.push(hotelsCategory);
            }
            return result;
        }
        // If we don't have a search term then we display `Anywhere` and `Recent searches`.
        // Destination Group
        const destinationGroup = (React.createElement(AutocompleterCategory, { id: "category-group" },
            React.createElement(AutocompleterList, null, anywhereOptions
                .map((d) => {
                const { id, type, title, subTitle, isCached } = d;
                return (React.createElement(AutocompleterOption, { key: `option-${id}-${type}`, id: `${id}-${type}`, value: `${id}-${type}`, title: title, subtitle: subTitle !== null && subTitle !== void 0 ? subTitle : '', icon: type === 1 ? React.createElement(HotelIcon, null) : React.createElement(LocationIcon, null), selectable: false, "data-id": `multi-autocomp-option-${isCached ? 'checkbox' : 'link'}` }));
            })
                .concat(parseBoolean(HCEnableDestinationGroups)
                ? Object.entries(destinationGroups).map(([_key, group]) => {
                    const { id, type, title, subTitle } = group;
                    return (React.createElement(AutocompleterOption, { key: `option-${id}-${type}`, id: `${id}-${type}`, value: `${id}-${type}`, title: title, subtitle: subTitle !== null && subTitle !== void 0 ? subTitle : '', icon: React.createElement(LocationIcon, null), selectable: true, "data-id": "multi-autocomp-option-link" }));
                })
                : []))));
        result.push(destinationGroup);
        const recentCategory = getRecentCategory();
        if (recentCategory) {
            result.push(recentCategory);
        }
        return result;
    };
    const getSecondColumnCategories = () => {
        const result = [];
        // If we have a search term then we want to display matching hotels
        // (but only if they were not displayed in the first column).
        if (withSearchTerm) {
            if (destinationsCategory && hotelsCategory) {
                result.push(hotelsCategory);
            }
            return result;
        }
        // If we don't have a search term then we display default destinations.
        if (destinationsCategory) {
            result.push(destinationsCategory);
        }
        return result;
    };
    const getSecondColumnStartIndex = () => {
        // Get the start index for the second column of results. Thsi is required for keyboard navigation
        // (via arrow keys )to work accross the colums.
        if (withSearchTerm) {
            return destinationOptions.length > 0 ? destinationOptions.length + 1 : hotelOptions.length + 1;
        }
        return recentOptions.length + 2 + Object.keys(destinationGroups).length; // Also includes `Anywhere`
    };
    // Build the final results element including "no matches" case for empty searches or apollo errors
    const autoCompleterResults = () => {
        if (!called || loading) {
            return (React.createElement(FlexGrid, { container: true, spacing: SEARCH_FIELD_POPOVER_INNER_PADDING, disableEqualOverflow: true },
                React.createElement(FlexGrid, { xs: 12, l: 6 },
                    React.createElement(AutocompleterSkeleton, { multiSelect: true })),
                React.createElement(FlexGrid, { xs: 12, l: 6 },
                    React.createElement(AutocompleterSkeleton, { multiSelect: true }))));
        }
        if (matchesFound) {
            return (React.createElement(React.Fragment, null,
                searchState.destinations && searchState.destinations.length > 0 && !dialog && (React.createElement(Styler, { mt: 1, mb: 3 }, selectedOptionPills(true, false))),
                React.createElement(FlexGrid, { container: true, spacing: SEARCH_FIELD_POPOVER_INNER_PADDING, disableEqualOverflow: true },
                    React.createElement(FlexGrid, { xs: 12, l: 6 },
                        React.createElement(AutocompleterResults, { id: autocompleterListId, label: "Suggested Destinations", spacing: 3 }, getFirstColumnCategories())),
                    React.createElement(FlexGrid, { xs: 12, l: 6 },
                        React.createElement(AutocompleterResults, { id: autocompleterListId, label: "Suggested Destinations", startIndex: getSecondColumnStartIndex() }, getSecondColumnCategories())))));
        }
        return React.createElement(AutocompleterNoMatches, null);
    };
    const fieldError = (React.createElement(FieldErrorMessage, { error: error, message: SEARCH_DESTINATION_ERROR_EMPTY, "data-id": "destination-error-message" }));
    const selectionErrorNotification = selectionError ? (React.createElement(Notification, { icon: React.createElement(InformationIcon, null), color: "info", variant: "compact", mb: 2 }, NONCACHED_FIELD_ERROR_MESSAGE)) : null;
    const label = SEARCH_DESTINATION_FIELD_LABEL;
    const placeholder = SEARCH_DESTINATION_FIELD_PLACEHOLDER;
    const handleOpen = useCallback(() => {
        onOpen(SearchFieldEnum.DESTINATION);
        setSearchTerm('');
        searchDispatch({
            type: SearchDispatchActionType.REMOVE_NONCACHED,
        });
    }, [onOpen, searchDispatch]);
    const handleClickOrFocus = () => {
        if (!open) {
            handleOpen();
        }
    };
    const handleClearSearchTerm = () => {
        setSearchTerm('');
    };
    const commonFieldProps = useMemo(() => ({
        fullWidth: true,
        error,
        variant: 'alternative',
    }), [error]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Autocompleter, { fetch: fetchData, onAdd: handleAdd, onRemove: handleRemove, selectedValue: selectedValues, multiSelect: true },
            React.createElement(React.Fragment, null,
                popup && open && (React.createElement(SearchFieldPopover, { open: open, onClose: () => onPopupClose(), placement: popupPlacement, error: searchError, dynamicHeight: true, scrollingElement: scrollingPopoverElement },
                    React.createElement(PopoverAnchor, null,
                        React.createElement(AutocompleterField, Object.assign({ id: autocompleterFieldId, listId: autocompleterListId, value: error && searchClicked ? '' : searchTerm, label: label, placeholder: placeholder, onChange: handleFieldChange, autoFocus: true, shrinkLabel: true, size: { xs: 'm', m: 'l' }, showClear: withSearchTerm, onButtonClick: handleClearSearchTerm }, commonFieldProps))),
                    React.createElement(SearchFieldPopoverPanel, { width: 740, scrollingElementRef: (ref) => setScrollingPopoverElement(ref) },
                        selectionErrorNotification,
                        autoCompleterResults()))),
                !open && (
                // A button acts as a trigger to open the popup or dialog.
                React.createElement(ButtonField, Object.assign({ value: summary, label: label, onClick: handleClickOrFocus, onFocus: () => handleClickOrFocus(), size: { xs: 'm', m: 'l' } }, commonFieldProps))),
                fieldError),
            dialog && (React.createElement(FieldDialog, { open: open, onClose: onClose, title: label, headingProps: {
                    component: 'label',
                    htmlFor: dialogAutocompleterFieldId,
                }, ctaProps: {
                    onClick: () => {
                        if (onDone) {
                            onDone();
                        }
                    },
                    label: 'Next',
                    disabled: !withSelections,
                }, headerContent: React.createElement(React.Fragment, null,
                    React.createElement(AutocompleterField, Object.assign({ id: dialogAutocompleterFieldId, listId: autocompleterListId, value: searchTerm, placeholder: placeholder, onChange: handleFieldChange, autoFocus: true, icon: React.createElement(SearchIcon, null), showClear: withSearchTerm, onButtonClick: handleClearSearchTerm }, commonFieldProps)),
                    fieldError,
                    searchState.destinations && searchState.destinations.length > 0 && (React.createElement(Styler, { mt: 3 }, selectedOptionPills(true, true)))) },
                selectionErrorNotification,
                autoCompleterResults()))),
        errorProp && React.createElement("div", null, errorProp.message)));
}
